.FadeIn {
    opacity: 0;
    animation: 1s FadeIn forwards;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
