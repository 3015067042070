.LoadBullet{
    width: 48px;
    height: 48px;
    object-fit: contain;
    animation: wave 2.3s linear infinite;
    &_1{
        @extend .LoadBullet;
        animation-delay: -3.5s;
    }
    &_2{
        @extend .LoadBullet;
        animation-delay: 0s;
    }
    &_3{
        @extend .LoadBullet;
        animation-delay: -4s;

    }
}
@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-40px);
	}
}

.errorBullet{

}