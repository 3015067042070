.EventCardWithDescription{
  min-width: 144px;
  display: flex;
  flex-direction: column;

  & > h2{
    line-height: 14px;
    white-space: normal;
  }
  
  &_date{
    padding: 4px 7px;
    border-radius: 6px;
    background: var(--Light-Grey, #EBF0F6);
    color: #101011;
  }
}