.PhotoMaterial{
  display: flex;
  position: relative;
  border-radius: 12px;
  width: 100%;
  & > img{
    border-radius: 12px;
    width: 100%;
    object-fit: cover;
  }
  &_bookmark{
    position: absolute;
    top: 12px;
    right: 14.5px;
    & > img{
      width: 34px;
      height: 34px;
    }
  }
}
.PhotoMaterialWide{
  @extend .PhotoMaterial;
  grid-column-start: span 2;
}