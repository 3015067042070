.Flex{
  display: flex;
  align-items: center;
  justify-content: center;
  &.row{
    flex-direction: row;
  }
  &.column{
    flex-direction: column;
  }
}
