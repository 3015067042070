.Tabs{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  border-radius: 10px;
  background: #EBF0F6;
  width: 100%;
  padding: 12px 0;
  &__option{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 50%;
    cursor: pointer;
    z-index: 2;
    &1{
      @extend .Tabs__option;
      &.option{
        &1{
          &.Tabs__option1{
            color: white;
            transition: 0.5s;
          }
        }
        &2{
          &.Tabs__option2{
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
    &2{
      @extend .Tabs__option;
      &.option{
        &1{
          &.Tabs__option1{
            color: white;
            transition: 0.5s;
          }
        }
        &2{
          &.Tabs__option2{
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
  }
  &__background{
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 10px;
    background: #101011;
    transition: 0.5s;
    &.option{
      &1{}
      &2{
        transform: translate(100%);
      }
    }
  }
}