.Checkbox{
  position: relative;

  & input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  & img{
    width: 24px;
    height: 24px;
  }
}