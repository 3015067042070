.PageLayout{
    min-height: 100vh;
    height: fit-content;
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    flex: 1 1 auto;
    position: relative;
    &__content{
        flex: 1 1 auto;
        overflow-y: scroll;
        &.navbar{
            margin-bottom: 30px;
        }
    }   
    &__navbar{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
}