.Tab{
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 100px;
  background: #EBF0F6;
  cursor: pointer;
}
.ActiveTab{
  @extend .Tab;
  background: #101011;
  color: #FFFFFF;
}
