.TicketModal{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px 24px 0 0;
  background: linear-gradient(180deg, #F3F7FC 0%, #EBF0F6 100%);
  padding: 24px 16px 32px 16px;
  &_close{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 16px;
  }
  &_noodle{
    width: 350px;
    position: absolute;
    top: -30px;
  }
  &_content{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_info{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px 20px 0 0;
      background: rgba(255, 255, 255, 0.98);
      padding: 16px 16px 0 16px;
      & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        background: #EBF0F6;
        padding: 4px 40px;
        margin-top: 12px;
      }
    }
    &_QR{
      width: 100%;
      position: relative;
      & > img{
        width: 100%;
        height: 100%;
      }
    }
  }
}