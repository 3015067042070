.EventsMaterials{
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  width: 100%;
  padding: 16px;
  overflow-y: scroll;
  & > img{
    width: 100%;
    object-fit: contain;
    border-radius: 24px;
  }
  &_tabs{
    display: flex;
    gap: 4px;
  }
  &_photo{
    grid-gap: 4px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    &__item{
      display: inline-block;
      width: 100%;
      break-inside: auto;
      &>.PhotoMaterial>img {
        display: block;
        object-fit: cover;
        width: 100%;
      }
      &.landscape{
        grid-column-end: span 2;
      }
    }
  }
  &_files{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &_links{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &_empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    & > h2{
      color: #6A727C;
    }
    & > img{
      width: 200px;
      height: 200px;
    }
  }
}