.EventRegistrationCard{
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 20px;
  background: var(--white-color);
  &_place{
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
  }
}