.ModalContent{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 16px 32px 16px;
    flex: 1 1 auto;
    border-radius: 24px 24px 0px 0px;
    background: var(--Light-Grey, #EBF0F6);
    box-shadow: inset 0px 10px 10px rgba(99, 125, 170, 0.14);
    justify-content: flex-start;
    overflow-y: scroll;
  
    &__close{
        width: 24px;
        height: 24px;
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 2;
    }
}