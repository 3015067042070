.PhotoPage{
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  overflow-y: scroll;
  &_download{
    display: flex;
    gap: 4px;
    padding: 5px 9px;
    border-radius: 10px;
    background: #EBF0F6;
    & > img{
      width: 24px;
      height: 24px;
    }
  }
  &_iconWrapper{
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    background: #EBF0F6;
    & > img{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &_swiper{
    &.swiper {
      width: 100%;
      height: 100%;
    }
  }
  &_swiperSlide{
    &.swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100svh - 50px - 16px);
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}