.Navbar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid var(--Light-Grey, #EBF0F6);
  background: #FBFBFD;
  &.iOS{
    padding: 0 16px 24px 16px;
  }
  &_element{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 8px 0 4px 0;
    & > img{
      width: 24px;
      height: 24px;
    }
  }
}