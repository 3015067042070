.EventList{
  width: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  padding-bottom: 62px;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
  &_events{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &_noEvents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    & > img{
      width: 200px;
      height: 200px;
    }
    & > h1{
      text-align: center;
    }
  }
}