.EventCard{
  background-color: #e9eef5;
  border-radius: 24px;
  &_imageWrapper{
    position: relative;
    height: 193px;
    border-radius: 24px;
    &_preview{
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
    &_bookmark{
      position: absolute;
      top: 12px;
      right: 12px;
      width: 38px;
      height: 38px;
    }
  }
  &_chip{
    display: flex;
    gap: 4px;
    width: fit-content;
    padding: 4px 12px;
    border-radius: 6px;
    background: #101011;
    color: var(--white-color);
  }
  &__description{
    padding: 16px;
  }
}