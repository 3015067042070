.EventFilters{
  display: flex;
  gap: 4px;
  overflow: scroll;
  white-space: nowrap;
  & > img{
    width: 36px;
    height: 36px;
  }

}