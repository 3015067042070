.BookmarkImage{
  position: relative;
  border-radius: 24px;
  &_image{
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
  &_bookmark{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 38px;
    height: 38px;
  }
}