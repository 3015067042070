.DropdownList{
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  background: #EBF0F6;
  overflow: hidden;
  &_question{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    & > img{
      width: 24px;
      height: 24px;
    }

  }
  &_expanded{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
