.FavouritesPage{
  width: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 62px 16px;
  background: #FEFEFF;
  overflow-y: scroll;
  &_events{
    display: flex;
    flex-direction: column;
    gap: 24px;
    & span{
      font-size: 16px;
      font-weight: 500;
    }
    & h2{
      font-size: 18px;
      font-weight: 600;
    }
  }
  &_dateChip{
    background: #DEE6F0 !important;
  }
  &_empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto 0;
    & > h2{
      color: #6A727C;
    }
    & > img{
      width: 200px;
      height: 200px;
    }
  }
}