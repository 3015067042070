.EventRegistration{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 77px 16px;
  &_register{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, #FFF 100%);
    backdrop-filter: blur(0.5px);
    width: 100%;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}