.FeedbackModal{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 32px 16px;
  border-radius: 24px 24px 0 0;
  background: #F1F5F9;
  &_close{
    width: 24px;
    height: 24px;
  }
  & > h2{
    align-self: flex-start;
  }
  &_rating{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  & > textarea{
    width: 100%;
    height: 136px;
    padding: 12px 16px;
    border-radius: 10px;
    border: 1px solid #DEE6F0;
    background: #EBF0F6;
    font-family: "Manrope", serif;
    font-size: 14px;
    font-weight: 400;
    &::placeholder{
      font-family: "Manrope", serif;
      font-size: 14px;
      font-weight: 400;
      color: #939BA4;
    }
  }
}