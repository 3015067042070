.AboutPage{

}
.CompanyCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFF;
    padding: 13px 13px;
    border-radius: 16px;
    min-height: 161px;
    justify-content: space-around;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0 rgba(106, 123, 143, 0.06);

    &__image{
        width: 100%;
        max-height: 57px;
        object-fit: contain;
    }
    
    &__description{

    }
}
.CompanyModal{
    flex: 1 1 auto;
    width: 100%;
    &__socials{
        margin-top: auto;
    }
}