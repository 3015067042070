.DropdownFilter{
  display: flex;
  gap: 4px;
  padding: 6px 8px 6px 16px;
  border-radius: 100px;
  background: #EBF0F6;
  align-items: center;
  & img{
    width: 24px;
    height: 24px;
  }
}