.Background{
  width: 100%;
  height: 100svh;
  position: relative;
  background: #FEFEFF;
  &_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    height: 100svh;
    width: 100%;
    z-index: 0;
  }
  &_circle1{
    position: absolute;
    border-radius: 291.425px;
    background: rgba(99, 125, 170, 0.14);
    filter: blur(73.84083557128906px);
    width: 250.074px;
    height: 291.425px;
    bottom: 66px;
    right: -127px;
  }
  &_circle2{
    position: absolute;
    border-radius: 281.58px;
    background: rgba(99, 125, 170, 0.14);
    filter: blur(59.07266616821289px);
    width: 194.94px;
    height: 281.58px;
    top: 118px;
    left: -51px;
  }
  &_circle3{
    position: absolute;
    border-radius: 378.065px;
    background: rgba(255, 255, 255, 0.80);
    filter: blur(49.22722244262695px);
    width: 378.065px;
    height: 293.394px;
    bottom: 64px;
    right: 18px;
  }
  &_circle4{
    position: absolute;
    border-radius: 315.403px;
    background: rgba(255, 255, 255, 0.80);
    filter: blur(65px);
    width: 204.991px;
    height: 315.403px;
    transform: rotate(-13.722deg);
    top: 52px;
    right: 33px;
  }
  &_circle5{
    position: absolute;
    border-radius: 519.839px;
    background: rgba(99, 125, 170, 0.14);
    filter: blur(173.74313354492188px);
    width: 519.839px;
    height: 519.839px;
    top: 18px;
    right: -297px;
  }
  &_circle6{
    position: absolute;
    border-radius: 736.439px;
    opacity: 0.8;
    background: rgba(99, 125, 170, 0.14);
    filter: blur(246.1361083984375px);
    width: 736.439px;
    height: 736.439px;
    top: -323px;
    right: -94px;
  }
}