.TextInput{
  display: flex;
  flex-direction: column;
  gap: 8px;
  & input{
    border-radius: 10px;
    height: 43px;
    width: 100%;
    padding: 12px 16px;
    background: rgba(132, 155, 181, 0.12);
    &::placeholder{
      color: #6E6E71;
    }
  }
  & textarea{
    border-radius: 10px;
    height: 43px;
    width: 100%;
    padding: 12px 16px;
    background: rgba(132, 155, 181, 0.12);
    min-height: 136px;
    &::placeholder{
      color: #6E6E71;
    }
  }
  & h1{
    color: #FF324B;
  }
}