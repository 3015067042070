:root {
--main-gradient: linear-gradient(107.22deg, #8460EC 7.36%, #F22871 128.49%);
--gradient: linear-gradient(85.52deg, #F22871 21.7%, #2CACC2 81.31%);
--size: calc(1vw + 1vh);
--black-color: black;
--white-color: #fff;
--background-color: #131313;
--main-box-shadow: inset 1000px 1000px #131313;
}
* {
box-sizing: border-box;
scroll-behavior: smooth;
    z-index: 1;
}
html, body {
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    min-height: 100vh;
    margin: 0 auto;
    background: var(--white-color);
    scroll-behavior: smooth;
}
#root{
    min-height: 100vh;
    height: fit-content;
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    flex: 1 1 auto;
}
$fontSizes: 10, 12, 14, 16, 18, 22, 24, 28;
@each $i in $fontSizes{
    .text-#{$i}{
        font-size: $i + px;
    }
}
$marginTop: 3, 4, 8, 10, 12, 16, 24, 30, 32, 40, 70, 96;
@each $i in $marginTop{
    .m-#{$i}{
        margin: $i + px;
    }
    .mt-#{$i}{
        margin-top: $i + px;
    }
    .mb-#{$i}{
        margin-bottom: $i + px;
    }
    .g-#{$i} {
        gap: $i + px;
    }
    .p-#{$i} {
        padding: $i + px;
    }
    .py-#{$i} {
        padding: $i + px 0;
    }
    .px-#{$i} {
        padding: 0 $i + px;
    }
    .br-#{$i} {
        border-radius: $i + px !important;
    }
    .ln-#{$i}{
        line-height: $i + px;
    }
}
.bold {
    font-weight: 700;
}

.semibold {
    font-weight: 600;
}

.medium {
    font-weight: 500;
}

.regular {
    font-weight: 400;
}

.light {
    font-weight: 300;
}
.invisibleScroll {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none; 

    &::-webkit-scrollbar {
        display: none;
        width: 0px !important;
        border-radius: 0px !important;
        border: 0px !important;
        background-color: transparent !important;
        background-clip: unset !important;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px var(--background-color) !important;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      outline: 0px solid transparent !important;
    }
}