.RatingEmoji{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 110px;
  padding: 13.192px;
  background: #EBF0F6;
  & > img{
    width: 33px;
    height: 33px;
  }
  &_active{
    background: #FF7539;
  }
}