.ArrowButton{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 16px;
  border-radius: 10px;
  background: #DEE6F0;
  box-shadow: 2px 3px 7px 0 rgba(0, 0, 0, 0.02);
  & > div{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & img {
    width: 24px;
    height: 24px;
  }
}