.RichText {
  all: initial;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  color: var(--begie) !important;
  font-weight: inherit;

  * {
    color: inherit !important;
    font-family: inherit;
    font-weight: inherit;
  }
  a {
    text-decoration: underline;
  }
  p {
    margin: 8px 0;
  }

  ul {
    list-style-position: inside;
    list-style-type: disc;
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;
  }

  li {
    list-style-type: inherit;

    * {
      display: inline-flex;
    }

    &::marker {
      padding: 0 !important;
      margin-right: 4px !important;
    }
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }
}