.DefaultButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  border-radius: 100px;
  background: #101011;
  color: var(--white-color);
}
.OrangeButton{
  @extend .DefaultButton;
  background: #FF7539;
}
.ReverseButton{
  @extend .DefaultButton;
  background: #DEE6F0;
  color: #101011;
}
.GreyButton{
  @extend .DefaultButton;
  background: #A8AFB9;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 10px 16px;
}