.ModalFilterInterests{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border-radius: 24px 24px 0 0;
  background: var(--50, #F1F5F9);
  padding: 24px 16px 32px 16px;
  position: relative;
  & > img{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 16px;
  }
}