.FileMaterial{
  padding: 16px;
  border-radius: 20px;
  background: #EBF0F6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_placeholder{
    width: 44px;
    height: 44px;
  }
  &_favourite{
    width: 24px;
    height: 24px;
  }
  & h2{
    color: #6A727C;
  }
}