.CustomCalendar{
  display: flex;
  flex-direction: column;
  background: #F1F5F9;
  border-radius: 24px 24px 0 0;
  padding: 24px 16px 32px 16px;
  width: 100%;
  &_range{
    display: flex;
    justify-content: space-between;
    & > h1{
      color: #6A727C;
      & > span{
        color: #101011;
      }
    }
  }
  &_buttons{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  &_fullEventDay{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #FF7539;
      border-radius: 50%;
      top: 2px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  &_selectedDay{
    background: #101011 !important;
    color: white !important;
    &.react-calendar__tile--active{
      color: white !important;
      background: #101011 !important;
    }
    &.react-calendar__month-view__days__day--weekend {
      color: white !important;
      &.react-calendar__tile--active{
        background: #101011 !important;
      }
    }
    &.react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus, {
      background: #101011 !important;
      color: white !important;
    }
    &.react-calendar__tile--now {
      background: #101011 !important;
      color: #FF324B !important;
      &.react-calendar__tile--active:enabled:focus {
        background: #101011 !important;
        color: #FF324B !important;
      }
    }
  }
}
.react-calendar {
  width: 100% !important;
  border: none !important;
  background: transparent !important;
}
.react-calendar__navigation{
  font-size: 22px !important;
  font-weight: 500 !important;
  font-family: "Manrope", serif !important;
}
.react-calendar__navigation button {
  min-width: fit-content !important;
  padding: 0 !important;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}
.react-calendar__tile{
  width: 34px !important;
  height: 34px !important;
  margin: auto !important;
  border-radius: 17px !important;
  padding: 0 !important;
  font-family: Manrope, serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.react-calendar__month-view__days{
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 8px;
}

.react-calendar__tile--now {
  background: transparent !important;
  color: #FF324B !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #101011 !important;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #A8AFB9 !important;
}
.react-calendar__month-view__weekdays{
  color: #6A727C !important;
  font-family: Manrope, serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  & abbr[title] {
    text-decoration: none !important;
  }
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active{
  background: transparent !important;
  color: #101011 !important;
}
