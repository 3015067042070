.LinkMaterial{
  padding: 16px;
  border-radius: 20px;
  background: #EBF0F6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h2{
    color: #6A727C;
  }
  & a{
    color: #146BCA;
  }
  & > img{
    width: 33px;
    height: 33px;
  }
}